.succ-register{
    padding: 50px 0;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.succ-register .succ-img{
    max-width: 220px;
    margin-bottom: 30px;
}
.succ-register .succ-img img{
    max-width: 100%;
}

.succ-register .succ-desc{
    font-size: 26px;
    font-weight: bold;
    color: var(--mainColor);
    margin-bottom: 30px;
}

.succ-register .go-to-btn{
    background-color: var(--mainColor);
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
}

@media (max-width:599px) {

}
@media (min-width:600px) {

}
@media (min-width:900px) {
    .succ-register{
        padding: 80px 0;
    }
    .succ-register .succ-img{
        max-width: 300px;
    }
    
    .succ-register .succ-desc{
        font-size: 30px;
    }
}
@media (min-width:1200px) {

}
@media (min-width:1536px) {

}
