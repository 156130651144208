.transfer-money {
    padding: 50px 0;
}

.transfer-money .send-balance-container {
    display: flex;
    flex-direction: column;
}

.transfer-money .send-balance-container .send-money,
.transfer-money .send-balance-container .balance,
.transfer-money .latest-transfer{
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    padding: 16px;
    border-radius: 10px;
}

.transfer-money .send-balance-container .send-money {
    flex: 0 0 59%;
    margin-bottom: 25px;
}

.transfer-money .send-balance-container .balance {
    flex: 0 0 39%;
    margin-bottom: 25px
}

.transfer-money .send-balance-container .send-money .title,
.transfer-money .send-balance-container .balance .title ,
.transfer-money .latest-transfer .title {
    font-size: 36px;
    font-weight: bold;
    color: var(--mainColor);
    margin-bottom: 30px;
    position: relative;
    width: fit-content;
}

.transfer-money .send-money .send-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.transfer-money .send-money .send-info .input,
.transfer-money .send-money .send-info .send-btn {
    width: 70%;
    margin-bottom: 25px;
    font-size: 18px;
}

.transfer-money .balance .balance-amount {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 50px;
}

.transfer-money .balance .balance-amount span {
    font-size: 70px;
    font-weight: bold;
}

.transfer-money .balance .recharge-btn {
    width: 70%;
    margin: auto;
    display: block;
}

.transfer-money .send-money .send-info .send-btn,
.transfer-money .balance .recharge-btn {
    font-size: 20px;
    font-weight: bold;
    padding: 8px 0;
}

.transfer-money .latest-transfer .transfer-item{
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 25px;
}

.transfer-money .latest-transfer .transfer-item::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 1px;
    background-color: #777;
    transition: .3s;
}

.transfer-money .latest-transfer .transfer-item .transfer-img {
    margin-right: 20px;
}

.transfer-money .latest-transfer .transfer-item .transfer-img img {
    max-width: 100%;
}

.transfer-money .latest-transfer .transfer-item .transfer-info {
    display: flex;
    flex-direction: column;
    flex: 0 0 80%;
    padding-left: 20px;
}
.transfer-money .latest-transfer .transfer-item .transfer-name {
    font-size: 24px;
    font-weight: bold;
    color: var(--mainColor);
    margin-right: 70px;
    margin-bottom: 10px;
}
.transfer-money .latest-transfer .transfer-item .transfer-price{
    display: flex;
    font-size: 18px;
    font-weight: 500;
}

.transfer-money .latest-transfer .transfer-item .transfer-price .transfer-date{
    margin-left: 70px;
}
.transfer-money .latest-transfer .transfer-item span {
    font-size: 22px;
    font-weight: bold;
    color: var(--mainColor);
}


@media (max-width:599px) {
    .transfer-money .latest-transfer .transfer-item .transfer-name {
        font-size: 22px;
    }

    .transfer-money .latest-transfer .transfer-item .transfer-info {
        
        padding-left: 0;
    }

    .transfer-money .latest-transfer .transfer-item .transfer-price .transfer-date{
        margin-left: 0;
    }
}

@media (min-width:600px) {
    
}

@media (min-width:900px) {
    .transfer-money .send-balance-container {
        flex-direction: row;
        justify-content: space-between;
    }

    .transfer-money .latest-transfer .transfer-item .transfer-info{
        flex-direction: row;
    }

    .transfer-money .latest-transfer .transfer-item .transfer-name {
        margin-bottom: 0;
    }
}

@media (min-width:1200px) {}

@media (min-width:1536px) {}