.recharge-container{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 50;
    padding: 0 24px;
    background-color: #eeeeeead;
    transition: .3s;
}
.recharge{
    max-width: 500px;
    padding: 32px 16px;
    margin: auto;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 8px;
    margin-top: 200px;
    background-color: white;
    position: relative;
}

.recharge .close-btn{
    position: absolute;
    top: 8px;
    right: 8px;
    color: red;
    cursor: pointer;
}

.recharge .title {
    font-size: 36px;
    font-weight: bold;
    color: var(--mainColor);
    margin: auto;
    margin-bottom: 50px;
    position: relative;
    width: fit-content;
    
}

.recharge .title::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 2px;
    background-color: var(--mainColor);
}

.recharge .input-field{
    width: 80%;
}

.recharge .recharge-btn {
    width: 80%;
    background-color: var(--mainColor);
    padding: 10px 0;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
}

@media (max-width:599px) {
    .recharge .title {
        font-size: 22px;
    }
}
@media (min-width:600px) {

}
@media (min-width:900px) {

}
@media (min-width:1200px) {

}
@media (min-width:1536px) {

}