.main{
    background-color: var(--mainColor);
    color: var(--white);
    padding: 5px 0;
    position: relative;
}

.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo img{
    max-width: 100%;
}

.log-reg .btn{
    background-color: var(--white);
    margin: 0 10px;
    padding: 6px 12px;
    font-weight: bold;
}
.log-reg .btn:hover{
    color: var(--white);
}

.nav-bar .links .list-item{
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    transition: .3s;
}
.nav-bar .links .list-item:hover{
    color: var(--secondColor);
}

.profile{
    position: relative;
}

.profile-icon,
.menu-icon{
    cursor: pointer;
    transition: .3s;
}
.profile-icon:hover,
.menu-icon:hover{
    color: var(--secondColor);
}
.menu-icon{
    display: none !important;
}

.profile-menu{
    padding: 5px 10px;
    background-color: var(--mainColor);
    position: absolute;
    width: 130px;
    top: 54px;
    right: 0;
}
.profile-menu .profile-menu-item{
    font-weight: bold ;
    padding: 10px 0;
    cursor: pointer;
    color: var(--white);
    transition: .3s;
}
.profile-menu .profile-menu-item:hover{
    color: var(--secondColor);
}

/*Break Points*/
@media (max-width:599px) {
    .logo{
        max-width: 80px;
    }

    .log-reg .btn{
        margin: 0 5px;
        padding: 6px 8px;
        font-size: 12px;
    }

    .nav-bar{
        position: absolute;
        top: 100%;
        width: 100%;
        background-color: var(--mainColor);
        transition: .3s;
        z-index: 50;
    }
    .nav-bar .links .list-item{
        padding: 16px;
    }

    .menu-icon{
        display: block !important;
    }
}
@media (min-width:600px) {
    .logo{
        margin-right: 20px;
        max-width: 80px;
    }

    .log-reg .btn{
        margin: 0 10px;
        padding: 6px 12px;
        font-weight: bold;
    }

    .nav-bar{
        display: block !important;
        flex-grow: 1;
    }
    .nav-bar .links{
        display: flex;
    }
    .nav-bar .links .list-item{
        margin: 0px 10px;
        font-size: 16px;      
    }
}
@media (min-width:900px) {
    .logo{
        margin-right: 50px;
    }
    .nav-bar .links .list-item{
        margin: 0px 20px;
        font-size: 18px;
    }
}
@media (min-width:1200px) {
    
}
@media (min-width:1536px) {
    
}