.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
}

.login .form {
    flex: 0 0 60%;
}

.login .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 15px;
}

.login .form .icon {
    color: var(--mainColor);
    font-size: 120px;
    margin-bottom: 30px;
}

.login .form .box {
    display: flex;
    align-items: flex-end;
    width: 80%;
    margin-bottom: 30px;
}

.login .input-field {
    width: 100%;
}

.login .input-field label {
    color: var(--mainColor);
}

.login .input-field input {
    color: var(--mainColor);
}

.login .login-btn {
    width: 80%;
    background-color: var(--mainColor);
    padding: 10px 0;
    font-size: 16px;
    font-weight: bold;
}
.login .login-img{
    display: none;
}
.login .login-img img {
    max-width: 100%;
}

@media (max-width:599px) {
    .login .form {
        flex: 0 0 100%;
    }
}

@media (min-width:600px) {}

@media (min-width:900px) {
    .login-container {
        justify-content: space-between;
    }
    .login .form,
    .login .login-img {
        flex: 0 0 48%;
    }

    .login .login-img{
        display: block;
    }
}

@media (min-width:1200px) {}

@media (min-width:1536px) {}