.bill-component {
    display: flex;
    justify-content: center;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.bill-component::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 1px;
    background-color: #777;
}

.bill-component .bill-img{
    flex : 0 0 20%
}

.bill-component .bill-img img{
    max-width: 100%;
}

.bill-component .bill-info {
    flex: 0 0 85%;
    padding-left: 20px;
}

.bill-component .bill-name {
    font-size: 30px;
    font-weight: bold;
    color: var(--mainColor);
    margin-bottom: 10px;
}

.bill-component .bill-price-status ,
.bill-component .bill-date-category {
    display: flex;
    flex-direction: column;
}

.bill-component .bill-price,
.bill-component .bill-category {
    margin-bottom: 5px;
}

.bill-component .bill-price ,
.bill-component .bill-status ,
.bill-component .bill-category ,
.bill-component .bill-date {
    font-size: 18px;
    font-weight: 500;
}

.bill-component span {
    font-size: 22px;
    font-weight: bold;
    color: var(--mainColor);
}

.bill-component .bill-details{
    display: flex;
    justify-content: space-between;
} 

.bill-component .view-btn{
    font-size: 20px;
    font-weight: bold;
    padding: 8px 60px;
    display: block;
    margin-left: auto;
    margin-top: 15px;
    max-width: 300px;
    
}

@media (max-width:599px) {
    .bill-component{
        flex-direction: column;
        margin-bottom: 35px;
    }
    .bill-component .bill-info {
        flex: 0 0 100%;
        padding: 0 32px;
    }
    .bill-component .bill-img{
        text-align: center;
    }
    .bill-component .bill-details{
        flex-direction: column;
    }
    .bill-component .view-btn{
        font-size: 16px;
        font-weight: bold;
        padding: 8px 40px;
        display: block;
        margin-left: auto;
        margin-top: 15px;
        max-width: 300px;
    }
}

@media (min-width:600px) {

    .bill-component .bill-img{
        flex: 0 0 10%;
    }
    
}

@media (min-width:900px) {
    .bill-component .bill-details{
        flex-direction: column;
    }
    .bill-component .bill-price-status ,
    .bill-component .bill-date-category {
        flex-direction: row;
        justify-content: space-between;
    }

    .bill-component .bill-price ,
    .bill-component .bill-category {
        margin-bottom: 0;
    }
}

@media (min-width:1200px) {
    
}

@media (min-width:1536px) {}