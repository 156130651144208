@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

:root{
    --mainColor: #2192FF;
    --secondColor : #205295;
    --white: #fff; 
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body{
    font-family: 'Open Sans', sans-serif;
}

ul{
    list-style: none;
}

a{
    text-decoration: none;
    color: inherit;
}
.main-title{
    font-weight: bold;
    text-align: center;
    font-size: 38px;
    position: relative;
    margin-bottom: 80px;
}
.main-title::before{
    content: '';
    position: absolute;
    width: 79px;
    height: 6px;
    background-color: var(--white);
    left: 50%;
    bottom: -8px;
    transform: translateX(-50%);
    border-radius: 40px;
}

@media (max-width:599px) {

}
@media (min-width:600px) {

}
@media (min-width:900px) {

}
@media (min-width:1200px) {

}
@media (min-width:1536px) {

}