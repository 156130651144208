.success-container{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 50;
    padding: 0 24px;
    background-color: #eeeeeead;
    /* transition-delay: 3000ms; */
    transition: .3s .4s;
}

.success-container .success-box{
    max-width: 700px;
    padding: 60px 16px;
    margin: auto;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 8px;
    margin-top: 200px;
    background-color: white;
    position: relative;
    text-align: center;
}

.success-container .success-box .success-img{
    width: 50%;
    margin: auto;
}
.success-container .success-box .success-img img{
    width: 100%;
}

.success-container .success-box .close-btn{
    position: absolute;
    top: 8px;
    right: 8px;
    color: red;
    cursor: pointer;
}

.success-container .success-box .success-text{
    font-size: 24px;
    color: var(--mainColor);
    font-weight: bold;
    margin-top: 20px;
}